import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "lawn-chair-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1923) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={ className }
      id="heroBg"
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <div className="gPadding w-full h-full mx-auto max-w-1440 relative text-center md:text-right z-20">
        <div className="hero-text-container">
          <h1 className="logo-hero">Lawn <span className="-ml-2">Chair</span></h1>
          <p className="logo-hero-description">This Summer,<br/>Let Life Unfold.</p>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default BackgroundSection