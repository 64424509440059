import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

export default function MyModal() {
  const [isOpen, setIsOpen] = useState(true);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="MyModal max-w-lg w-full">

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="Age Gate"
        className="ageGate"
        overlayClassName="ageGateOverlay"
      >
        <div className="uppercase text-3xl lg:text-5xl tracking-wide leading-tight my-10">ARE YOU OF LEGAL DRINKING AGE?</div>
        <div className="my-5 flex justify-center text-center">
          <button className="bg-lawn-greenDark hover:bg-lawn-orange shadow-xl border border-black inline-block relative py-3 w-32 text-center uppercase text-xl md:text-3xl font-bold tracking-widest mx-2 md:mx-5" onClick={toggleModal}><span className="ml-1">Yes</span></button>
          <button className="bg-lawn-greenDark hover:bg-lawn-orange shadow-xl border border-black inline-block relative py-3 w-32 text-center uppercase text-xl md:text-3xl font-bold tracking-widest mx-2 md:mx-5" onClick={toggleModal}><span className="ml-1">No</span></button>
        </div>
        <div>
          <div className="font-debon text-6xl mt-10 mb-3">Lawn Chair</div>
          <p className="px-8 text-lg leading-tight mb-2">Like most websites, we&nbsp;use cookies to&nbsp;personalize and improve your browsing experience. View our <a href='/privacy-policy/' target="_blank" class='underline hover:text-lawn-greenDark'>Privacy&nbsp;Policy</a>.</p>
        </div>
      </Modal>
    </div>
  );
}