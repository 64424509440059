import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundSection from '../components/heroSection'
import { StaticImage } from "gatsby-plugin-image"
import MyModal from "../components/modal"

const IndexPage = () => (
  <Layout>
    <Seo title="Lawn Chair Sauvignon Blanc" />
    <MyModal />
    <BackgroundSection className="heroBg" />
    <div name="wine" id="wine" className="relative pt-32 pb-20 md:pb-48 z-40 -mt-48">
      <div className="gPadding w-full relative max-w-1440 mx-auto text-white flex flex-wrap flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center pt-8 md:pt-0">
          <h2>Have a Seat,<br/>Take a Sip</h2>
          <p className="text-2xl mt-8 tracking-wide">Lawn&nbsp;Chair is&nbsp;the perfect companion for the beach, the park or&nbsp;your backyard. Whether grilli&rsquo;n or&nbsp;chilli&rsquo;n, Lawn&nbsp;Chair is&nbsp;a&nbsp;good time every time.</p>
        </div>
        <div className="w-full md:w-1/2 relative flex justify-end pr-8 md:justify-center md:pr-0">
          <div className="relative md:absolute -mt-80 md:-mt-96">
            <StaticImage 
            src="../images/lawn-chair-sauvignon-blanc.png"
            alt="Lawn Chair Sauvignon Blanc"
            placeholder="blurred"
            className="bottle w-full"
            />
          </div>
        </div>
      </div>
      <div className="diagonal bg-lawn-green">
      </div>
    </div>
    
    <div className="relative py-20 md:pt-32 z-30 -mt-1">
      <div className="gPadding w-full relative max-w-1440 mx-auto text-white">
        <div className="max-w-3xl mx-auto text-center md:pt-48 relative">
          <div className="relative md:absolute -top-10 md:-top-24 -left-16 md:-left-20">
            <StaticImage 
            src="../images/sandals.png"
            alt="Sandals"
            placeholder="blurred"
            className="sandals"
            />
          </div>
          <h2>Hits All The Right Notes</h2>
          <p className="text-2xl mt-8 tracking-wide"><strong>Nose:</strong> Elegant and complex, with a&nbsp;perfectly balanced nose. Grassy, green notes together with elegant mineral aromas. Hints of&nbsp;citrus and tropical fruits, like passion fruit, pineapple and melon.</p>
          <p className="text-2xl mt-8 tracking-wide"><strong>Pairing:</strong> Perfect with good food and good people. Seafood, pasta dishes, mediterranean cuisine, Alex, Emily, maybe Steven, your favorite summer salad and cheese puffs. Or&nbsp;with nothing at&nbsp;all. Fantastic&nbsp;by&nbsp;the&nbsp;glass.</p>
        </div>
      </div>
      <div className="diagonal bg-lawn-orange">
      </div>
    </div>

    <div className="relative pt-20 pb-16 md:pb-32 z-30 -mt-1">
      <div className="gPadding w-full relative max-w-1440 mx-auto text-white text-center">
        <StaticImage 
          src="../images/lawn-chair-footer.png"
          alt="Lawn Chair"
          placeholder="blurred"
          className="lawn-chair-footer w-full relative inline-block"
          />
        <blockquote className="text-lawn-green text-2xl md:text-5xl lg:text-6xl xl:text-7xl tracking-wide font-medium mt-10 uppercase">"Easy to&nbsp;drink,<br/>easier to&nbsp;share"</blockquote>
      </div>
    </div>
  </Layout>
)

export default IndexPage
